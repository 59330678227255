<template>
  <div>
    Please Wait...
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  setup() {
    const route = useRoute()
    const store = useStore()

    onMounted(() => {
      if (!route.query.at) {
        return window.location = process.env.VUE_APP_BASE_URL_LOGIN
      }

      store.commit('auth/setAccessToken', { token: route.query.at }, { root: true })

      window.location = process.env.VUE_APP_BASE_URL_DASHBOARD
    })
  }
})
</script>